<template>
    <div>
        <CRow class="text-center">
          <template v-for="(icon, iconName) in $options.icons">
            <CCol class="mb-5" col="3" sm="2">
              <CIcon :height="42" :content="icon"/>
              <div>{{iconName}}</div>
            <div>{{ camelToKebab(iconName) }}</div>
            </CCol>
          </template>
        </CRow>
    </div>
  </template>
  
  <script>
  //import nav from './_nav'
  
    import cons from '@/const'
    import axios from "axios"
    import { freeSet } from '@coreui/icons'
    
    import * as icons from '@coreui/icons'

  
  export default {
    name: 'VerIconos',
    icons,
    data () {
      return {
        
      }
    },
    beforeMount() {
    },
    methods: {
        camelToKebab: function(str){
            return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        }
    }
  }
  </script>
  